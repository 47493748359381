import { Miniatura } from 'eureka-design'
import { obtenerLogo } from './util'
import nologo from '../imgs/no-logo.png'

/**
 * @param {Array} categorias 
 * @param {string} colText 
 * @param {string} colFoto 
 * @param {function} onClick 
 * @returns 
 */
export const adaptarCategorias = (
    categorias = [], 
    colText = 'nombre', 
    colFoto = 'foto',
    onClick = () => {}, 
) => {
    return categorias.map((categoria) => {
        return {
            text: categoria[colText],
            left: () => <Miniatura key={1} src={obtenerLogo(categoria[colFoto], 'foto', nologo)} />,
            onClick: () => {
                onClick(categoria)
            }
        }
    })
}

/**
 * @param {object} padre 
 * @returns 
 */
export const obtenerCategorias = (padre = {}) => {
    if(padre?.rutas)
        return padre?.rutas?.map((ruta) => ruta?.eureka_st_nivele_hijo)
    else
        return null
}