import { Tabla, buildData, ChipEstatus, Miniatura } from 'eureka-design'
import { useEffect, useState } from 'react'
import { useObtenerCategoriasQuery, useObtenerMedicinasQuery } from '../../services/medicina'
import icono from '../../imgs/icono.svg'
import { adaptarCategorias, obtenerCategorias } from '../../js/categoria'
import { obtenerLogo } from '../../js/util'

const CATEGORIA_MAX = 3

const ListaMedicinas = () => {
    const [totalActivos, fijaTotalActivos] = useState(0)
    const [totalArchivados, fijaTotalArchivados] = useState(0)

    /** CATEGORIA */
    const [pilaCategorias, fijaPilaCategorias] = useState([])
    const [categoriaAct, fijaCategoriaAct] = useState({ nombre: 'Categorias', fotoPortada: icono })
    const [subCategorias, fijaSubCategorias] = useState(null)
    const [nivelCategoria, fijaNivelCategoria] = useState(1)

    const { data, isLoading } = useObtenerMedicinasQuery({ nivel: categoriaAct?.id })
    const { data:categorias, isLoading:cargandoCategorias } = useObtenerCategoriasQuery()

    useEffect(() => {
        const calcularTotal = (pacientes = [], estatus = 'activo') => {
            return pacientes.filter((paciente) => paciente?.estatus === estatus).length
        }

        fijaTotalActivos(calcularTotal(data, 'activo'))
        fijaTotalArchivados(calcularTotal(data, 'archivado'))
    }, [data])

    const handlerRegresarCategoria = () => {
        if(nivelCategoria === 1) return
        else {
            let copiaPilaCategoria = [...pilaCategorias]
            let categoriaAnt = copiaPilaCategoria.pop()[0]
            fijaNivelCategoria(nivelCategoria - 1)
            fijaSubCategorias(obtenerCategorias(categoriaAnt))
            fijaPilaCategorias(copiaPilaCategoria)
            fijaCategoriaAct(categoriaAnt)
        }
    }

    const handlerSeleccionarCategoria = (categoria) => {
        if(nivelCategoria === CATEGORIA_MAX) return
        else {
            fijaNivelCategoria(nivelCategoria + 1)
            fijaSubCategorias(obtenerCategorias(categoria))
            fijaPilaCategorias([
                ...pilaCategorias,
                [{ ...categoriaAct }],
            ])
            fijaCategoriaAct(categoria)
        }
    }

    if(isLoading || cargandoCategorias) return
    else {
        return (
            <Tabla 
                checkbox={false}
                rowSize="medium"
                noData="No hay medicinas registradas"
                busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                keys={[
                    {
                        icono: 'fa-users',
                        text: "Todos",
                        detalle: totalActivos + totalArchivados,
                        filtro: () => true
                    },
                    {
                        icono: 'fa-circle-check',
                        text: "Activos",
                        detalle: totalActivos,
                        filtro: item => item.item.estatus === "activo"
                    },
                    {
                        icono: 'fa-triangle-exclamation',
                        text: "Archivados",
                        detalle: totalArchivados,
                        filtro: item => item.item.estatus === "inactivo"
                    },
                ]}
                headers={[
                    { key: 'nombre',  text: 'Nombre',       type: 'text' },
                    { key: 'tipo',    text: 'Presentación', type: 'text' },
                    { key: 'costo',   text: 'Precio',       type: 'precio' },
                    { key: 'estatus', text: 'Estado',       type: 'text' },
                ]}
                data={buildData(data, [
                    { key: 'nombre', type: 'text' },
                    { key: 'tipo',   type: 'text' },
                    { key: 'costo',  type: 'precio' },
                    { 
                        key: 'estatus',  
                        type: 'text',
                        text: (item) => {
                            const paciente = item
                            let texto, tipo
                            if(paciente.estatus === 'activo') {
                                texto = 'Activa'
                                tipo = 'Aceptado'
                            }
                            else {
                                texto = 'Suspendida'
                                tipo = 'Error' 
                            }
                            return <ChipEstatus texto={texto} tipo={tipo}/>
                        }
                    },
                ])}
                categorias={{
                    titulo: categoriaAct?.nombre,
                    left: () => <Miniatura key={1} src={obtenerLogo(categoriaAct?.fotoPortada, 'foto', icono)} />,
                    botones: [
                        { icono: 'fa-circle-left', tooltip: 'regresar', onClick: handlerRegresarCategoria },
                    ],
                    categorias: adaptarCategorias(subCategorias ?? categorias, 'nombre', 'fotoPortada', handlerSeleccionarCategoria),
                }}
            />
        )
    }
}

export default ListaMedicinas