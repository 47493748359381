import { Input, Select } from 'eureka-design'
import './SeccionMedicamento.css'
import { adaptarDatos } from '../../../js/selector'
import { tiempos } from '../../../js/receta'

const SeccionMedicamento = ({ medicamentos = [] }) => {
    return (
        <div className="seccion-principal">
            <div className="seccion-principal__input-principal">
                <Select 
                    placeholder="Buscar medicamento"
                    nombre="medicamento"
                    options={adaptarDatos(medicamentos, 'nombre', 'id')}
                    value={''}
                    busqueda={{
                        placeholder: 'Buscar...'
                    }}
                />
            </div>

            <div className="seccion-principal__input" style={{ width: '13%' }}>
                <p className="seccion-principal__input__placeholder">Tomar</p>
                <div className="seccion-principal__input__input" style={{ width: '60%' }}>
                    <Input 
                        type="number"
                        nombre="toma"
                        value="00"
                    />
                </div>
            </div>

            <div className="seccion-principal__input">
                <p className="seccion-principal__input__placeholder">Pastilla(s) cada</p>
                <div className="seccion-principal__input__input">
                    <Input 
                        type="text"
                        nombre="toma"
                        value="00"
                    />
                </div>
            </div>

            <div className="seccion-principal__input" style={{ width: '18%' }}>
                <p className="seccion-principal__input__placeholder">Horas durante</p>
                <div className="seccion-principal__input__input">
                    <Input 
                        type="number"
                        nombre="toma"
                        value="00"
                    />
                </div>
            </div>
            
            <div className="seccion-principal__input-secundario">
                <Select
                    placeholder="Periodo"
                    options={adaptarDatos(tiempos, 'texto', 'texto')}
                    value={''}
                />
            </div>
        </div>
    )
}

export default SeccionMedicamento